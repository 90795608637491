import React from "react";

class Page extends React.Component {
  render() {
    return (
      <div>
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              '\nBASE_URL = "/";\nIS_LOGGEDIN = "0";\nSITE_URL = BASE_URL;\nLANG_BASE_URL = "/";\n'
          }}
        />

        <script
          async
          type="text/javascript"
          src="/static/js/generated_js/464e67ac908110bb14244337c6d5d223.js?=1588071961"
        />
        <script async typ="text/javascript" src="/static/js/generated_js/jquery-migrate-1.4.1-min.js" />
        <div id="layout">
          <div className="head">
            <div className="inner">
              <a className="logo" href="/">
                <img src="/static/i/layout/New_Logo.png" alt="VISINE" />
              </a>
              <div className="menuHolder">
                <ul id="menu">
                  <li className="mainMenu">
                    <a href="/">Főoldal</a>
                  </li>
                  <li className="mainMenu">
                    <a href="/voros-szemre/">Vörös szemre</a>
                  </li>
                  <li className="mainMenu">
                    <a href="/termekeink/visine-classic/">
                      VISINE<sup>®</sup> Rapid
                    </a>
                  </li>
                  <li className="mainMenu">
                    <a className="active" href="/szemtorna/">
                      Szemtorna
                    </a>
                  </li>
                  <li className="mainMenu">
                    <a href="/kapcsolat/">Kapcsolat</a>
                  </li>
                </ul>
              </div>
              <div className="clear" />
            </div>
          </div>
          <div id="content" className="content">
            <div id="eyeGymnasticsPage">
              <div className="inner">
                <h1>Szemtorna</h1>
                <p className="desc">
                  A szemtorna nemcsak azoknak lehet hasznos, akik valamilyen
                  látásproblémával küzdenek, hanem azoknak is, akik sokat
                  olvasnak vagy sok időt töltenek monitor előtt. A gyakorlatok
                  segíthetnek ellazítani a feszülten figyelő szemet, megelőzni a
                  fáradtságát és kellemes érzést nyújtani. A torna egyszerre
                  lazíthatja és erősítheti a szemizmokat. Sok ember fókuszál egy
                  bizonyos irányba monitornézés vagy olvasás közben. Az, hogy a
                  szemizmok hosszabb ideig egy pozícióban tartják a szemet, azok
                  kifáradásához és szemszárazsághoz vezethet, mert ilyenkor
                  általában kevesebbet pislogunk. Már egy egyszerű, a szemet
                  ellazító gyakorlat is segíthet a szem jó állapotának
                  megőrzésében.
                </p>
                <div id="slider">
                  <div>
                    <div className="slideContent">
                      <h2>1. A szem fókuszálási képességének javítása</h2>
                      <p className="description">
                        Emelje maga elé az ujját vagy egy ceruzát, a szemétől
                        körülbelül 20 cm távolságra, és nézze figyelmesen, majd
                        hirtelen nézzen egy messzebb lévő tárgyra.
                        <br />
                        Csukja be a szemét 10 másodpercre, majd pislogjon egy
                        percen keresztül. A pislogás segíti a szem
                        hidratációját.
                      </p>
                    </div>
                    <img
                      src="/static/i/gymnastics/lesson22.png"
                      alt="1. feladat"
                    />
                  </div>
                  <div>
                    <div className="slideContent">
                      <h2>2. A szem hintázó mozgatása</h2>
                      <p className="description">
                        Csukja be a szemét, és képzeljen el egy ingát, mely
                        lassan, nagy amplitúdóval leng.
                        <br />
                        Kövesse a szemével az inga mozgását, miközben szeme
                        csukva van.{" "}
                      </p>
                    </div>
                    <img
                      src="/static/i/gymnastics/lesson01.png"
                      alt="2. feladat"
                    />
                  </div>
                  <div>
                    <div className="slideContent">
                      <h2>3. A szem vízszintes mozgatása</h2>
                      <p className="description">
                        Üljön le, támassza a hátát és fejét a falhoz, és lassan,
                        anélkül, hogy a fejét elmozdítaná nézzen el balra, majd
                        vissza egyenesen. Ezután nézzen el jobbra, majd
                        ismételten nézzen előre egyenesen.
                      </p>
                    </div>
                    <img
                      src="/static/i/gymnastics/lesson03.png"
                      alt="3. feladat"
                    />
                  </div>
                  <div>
                    <div className="slideContent">
                      <h2>4. A szem függőleges mozgatása</h2>
                      <p className="description">
                        Üljön le egy szobában, tartsa a fejét egyenesen és
                        közben nézzen fel a plafonra. Lassan, anélkül, hogy a
                        fejét megmozdítaná, nézzen rövid ideig egyenesen előre,
                        majd rövid idő múlva nézzen lefelé. Néhány másodperc
                        múlva nézzen ismét egyenesen előre.
                      </p>
                    </div>
                    <img
                      src="/static/i/gymnastics/lesson12.png"
                      alt="4. feladat"
                    />
                  </div>
                  <div>
                    <div className="slideContent">
                      <h2>5. A szemek befelé való mozgatása</h2>
                      <p className="description">
                        Ez egy kicsit nehezebb, koncentrációt igénylő gyakorlat.
                        Nyújtsa ki az egyik karját és mutassa fel egy ujját.
                        Egyik szemét csukja be, másik szemével pedig kövesse az
                        ujját, miközben lassan az arca felé mozgatja azt.
                        Ismételje meg többször a gyakorlatot váltott szemmel.
                      </p>
                    </div>
                    <img
                      src="/static/i/gymnastics/lesson04.png"
                      alt="5. feladat"
                    />
                  </div>
                  <div>
                    <div className="slideContent">
                      <h2>6. Szemmasszázs</h2>
                      <p className="description">
                        Üljön le és helyezze magát kényelembe. Ujjbegyeivel
                        masszírozza meg arcát, homlokát és halántékát körkörös
                        mozdulatokkal az arc széle felé haladva. Ezután ugyanezt
                        a mozdulatot használva masszírozza meg csukott szemét
                        is.
                      </p>
                    </div>
                    <img
                      src="/static/i/gymnastics/lesson21.png"
                      alt="6. feladat"
                    />
                  </div>
                  <div>
                    <div className="slideContent">
                      <h2>7. Pislogás</h2>
                      <p className="description">
                        Ez az egyik legegyszerűbb, mégis a legfontosabb lazító
                        gyakorlat. A pislogás beindítja a könnytermelést, ami
                        segít fenntartani a szem optimális hidratáltságát.
                        Amikor azt érzi, hogy szemei a sok munkától irritáltá
                        válnak, pislogjon többször egymás után mindkét szemével
                        egyszerre vagy akár külön-külön. A pislogás nedvesíti
                        szemeit és hozzájárul normál állapotának
                        visszanyeréséhez.
                      </p>
                    </div>
                    <img
                      src="/static/i/gymnastics/lesson19.png"
                      alt="7. feladat"
                    />
                  </div>
                </div>
                <div className="mainText">
                  <p>
                    A szemtorna gyakorlatok hasznosak lehetnek, de javasoljuk,
                    hogy először vegyen részt szakember által végzett
                    látásvizsgálaton és kérdezze meg szemészorvosát az Ön által
                    kipróbálni szánt szemtorna gyakorlatok hatékonyságával és
                    biztonságosságával kapcsolatban.
                  </p>
                </div>
              </div>
            </div>
            <div className="clear" />
          </div>
          <div className="footer">
            <div className="inner">
              <div className="productStatus">
                <div className="contentInner">
                  <div className="footerInner">
                    VISINE<sup>®</sup> Rapid 0,5 mg/ml oldatos szemcsepp. Vény
                    nélkül kapható, tetrizolin-hidroklorid hatóanyagú gyógyszer.
                  </div>
                </div>
              </div>
              <div className="footerMenu">
                <ul>
                  <li>
                    <a href="/jogi-nyilatkozat/">Jogi nyilatkozat</a>
                  </li>
                  <li>
                    <a href="/adatvedelmi-szabalyzat/">
                      Adatvédelmi szabályzat
                    </a>
                  </li>
                  <li>
                    <a href="/oldalterkep/">Oldaltérkép</a>
                  </li>
                  <li>
                    <a href="/cookie-szabalyzat/">Cookie szabályzat</a>
                  </li>
                  <li>
                    <a href="#" id="ot-sdk-btn" class="ot-sdk-show-settings">Cookie-beállítások</a>
                  </li>
                </ul>
              </div>
              <div className="johnson">
                <div className="contentInner">
                  <div className="footerInner">
                    <p className="bigger">©JNTL CONSUMER HEALTH (HUNGARY) KFT., 2023. 1112 Budapest, BudapestOne Center Balatoni út 2. a. ép. 1. em.</p>
                    <p>
                      Ezt az oldalt a JNTL Consumer Health (Hungary) Kft. tette közzé, és
                      felel annak tartalmáért.
                    </p>
                    <p>
                      Ez az oldal magyarországi felhasználók számára készült.
                    </p>
                    <p>Utoljára frissítve: 2023.02.01.</p>
                    <p>HU-VI-2200008</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="risks">
            <div className="contentInner">
              <div className="footerInner">
                A kockázatokról és a mellékhatásokról olvassa el a
                betegtájékoztatót, vagy kérdezze meg kezelőorvosát,
                gyógyszerészét!
              </div>
            </div>
          </div>
        </div>


      </div >
    );
  }
}

export default Page;
